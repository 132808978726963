// ============================
//    10. Widget css start
// ============================

.notification-card {
  text-align: center;
  color: #fff;

  h4 {
    color: #fff;
  }

  .card-body {
    padding: 0 !important;

    >div {
      margin-left: 0;
      margin-right: 0;
    }


    .notify-icon i {
      font-size: 30px;
    }

    .notify-cont {
      padding: 15px 0;
      border-left: 1px solid #fff;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.order-card {
  color: #fff;

  i {
    font-size: 26px;
  }
}

.trnasiction-card {
  .transection-preogress {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid transparent;
    padding: 12px 0;
    text-align: center;
    transform: rotate(45deg);

    p,
    span {
      color: #fff;
      transform: rotate(-45deg);
    }

    &.running-75 {
      border-top-color: #fff;
      border-right-color: #fff;
      border-bottom-color: #fff;
    }

    &.running-50 {
      border-top-color: #fff;
      border-right-color: #fff;
    }

    &.running-25 {
      border-top-color: #fff;
    }

    &.complited {
      border-color: #fff;
    }
  }

  .trnasiction-profile {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 20px 0;
    background-color: #ccc;
    text-align: center;
    color: #fff;
    margin: 30px auto;
  }

  .card-header .card-header-right {
    right: 16px;
    top: 16px;
  }

  .transection-footer {
    margin-left: 0;
    margin-right: 0;

    >div {
      padding: 15px 0;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.user-radial-card {
  text-align: center;

  >div {
    margin: 0 auto;
    display: block;

    img {
      box-shadow: 0 0 0 3px #fff;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.statustic-card {
  .card-body {
    position: relative;

    .progress {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 5px;
      overflow: visible;

      .progress-bar {
        position: relative;
        overflow: visible;

        &:before {
          content: "";
          height: 5px;
          width: 5px;
          border-radius: 50%;
          position: absolute;
          right: 0;
          background: inherit;
        }

        &.bg-grd-primary:before {
          animation: blue-blink-bar 0.5s linear infinite;
        }

        &.bg-grd-success:before {
          animation: green-blink-bar 0.5s linear infinite;
        }

        &.bg-grd-danger:before {
          animation: red-blink-bar 0.5s linear infinite;
        }

        &.bg-grd-warning:before {
          animation: yellow-blink-bar 0.5s linear infinite;
        }
      }
    }
  }
}

@keyframes blue-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($primary, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($primary, 0.7);
  }
}

@keyframes green-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($success, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($success, 0.7);
  }
}

@keyframes red-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($danger, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($danger, 0.7);
  }
}

@keyframes yellow-blink-bar {
  0% {
    box-shadow: 0 0 0 0 transparentize($warning, 0.9);
  }

  50% {
    box-shadow: 0 0 0 6px transparentize($warning, 0.7);
  }
}

.comp-card {
  i {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    padding: 17px 0;
    font-size: 18px;
  }
}

.prod-p-card {
  i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding: 11px 0;
    font-size: 24px;
  }
}

.proj-t-card {
  position: relative;
  overflow: hidden;

  .pt-badge {
    margin-bottom: 0;
    display: inline-block;
    padding: 60px 50px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: -35px;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
}

.ticket-card {
  .lbl-card {
    border-radius: 50px;
    padding: 5px 15px;
    display: inline-block;
  }
}

.analytic-card {
  .analytic-icon {
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 17px 0;
    border-radius: 50%;
    background-color: #fff;
  }
}

.user-card {
  .card-body {
    z-index: 1;

    .usre-image {
      position: relative;
      margin: 0 auto;
      display: inline-block;
      padding: 5px;
      width: 110px;
      height: 110px;

      img {
        z-index: 20;
        position: absolute;
        top: 5px;
        left: 5px;
      }

      &:after,
      &:before {
        content: "";
        width: 100%;
        height: 48%;
        border: 2px solid $primary;
        position: absolute;
        left: 0;
        z-index: 10;
      }

      &:after {
        top: 0;
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
      }

      &:before {
        bottom: 0;
        border-bottom-left-radius: 70px;
        border-bottom-right-radius: 70px;
      }
    }

    .activity-leval {
      li {
        display: inline-block;
        width: 14%;
        height: 4px;
        margin: 0 3px;
        background-color: #ccc;

        &.active {
          background-color: $success;
        }
      }
    }

    .counter-block {
      color: #fff;
      border-radius: var(--bs-border-radius-lg);

      p {
        margin-bottom: 0;
      }

      i {
        font-size: 20px;
      }
    }

    .user-social-link i {
      font-size: 30px;
    }
  }
}

.seo-card {
  .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: #000;
  }

  .seo-content {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;

    h4,
    h6 {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 50px;
        height: 2px;
        background-color: #fff;
      }
    }
  }

  .seo-statustic {
    position: absolute;

    &+.seo-chart {
      margin-top: 60px;
    }
  }
}

#seo-card1,
#seo-card2 {
  height: 120px;
}

.feed-blog {
  border-left: 1px solid #d6d6d6;
  margin-left: 30px;

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 50px;

    h6 {
      line-height: 1.5;
      cursor: pointer;
    }

    .feed-user-img {
      img {
        width: 40px;
        height: 40px;
      }

      position: absolute;
      left: -20px;
      top: -10px;

      &:after {
        content: "";
        position: absolute;
        top: 3px;
        right: 3px;
        border: 3px solid transparent;
        border-radius: 50%;
      }
    }

    &.active-feed {
      .feed-user-img:after {
        border-color: $success;
      }
    }

    &.diactive-feed {
      .feed-user-img:after {
        border-color: $danger;
      }
    }
  }
}

.social-card {
  color: #fff;

  .owl-carousel .owl-item img {
    width: 20px;
    height: 20px;
    float: right;
  }

  .owl-dots {
    text-align: center;

    .owl-dot {
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px;
      border: 1px solid #fff;
      background-color: transparent;
      transition: all 0.3s ease-in-out;

      &.active {
        background-color: #fff;
      }
    }
  }
}

.activity-blog {
  margin-left: 60px;
  list-style: none;

  li {
    position: relative;
    padding-bottom: 20px;
    padding-top: 20px;

    &+li {
      border-top: 1px solid var(--bs-border-color);
    }

    h6 {
      line-height: 1.5;
      cursor: pointer;
    }

    .activity-user-img {
      position: absolute;
      left: -50px;
      top: 20px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.new-cust-card {
  img {
    width: 40px;
  }

  h6 {
    margin-bottom: 0;
  }

  .align-middle {
    position: relative;

    .status {
      position: absolute;
      right: 0;
      top: 19px;
      font-size: 13px;

      &.active {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $success;
      }
    }
  }
}

.latest-update-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        background: #ebebeb;
        height: 100%;
        width: 3px;
        top: 0;
        left: 11px;
        z-index: 1;
      }

      .update-meta {
        z-index: 2;

        .update-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          padding: 7px 8px;
          font-size: 16px;
          color: #fff;

          &.bg-c-red {
            box-shadow: 0 0 0 4px transparentize($danger, 0.5);
          }

          &.bg-c-blue {
            box-shadow: 0 0 0 4px transparentize($primary, 0.5);
          }

          &.bg-c-green {
            box-shadow: 0 0 0 4px transparentize($success, 0.5);
          }

          &.bg-c-yellow {
            box-shadow: 0 0 0 4px transparentize($warning, 0.5);
          }

          &.ring {
            border-width: 3px;
            width: 15px;
            height: 15px;
            padding: 0;
            display: block;
            background: #fff;
          }
        }

        i.update-icon {
          margin-left: 4px;
          margin-right: 4px;
        }

        img.update-icon {
          padding: 0;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.latest-update-card {
  &.update-card {
    .card-body {
      .latest-update-box {
        &:after {
          left: 19px;
        }
      }
    }
  }
}

.testimonial-card {
  .progress {
    height: 5px;
  }

  .review-block {
    .cust-img {
      width: 40px;
      height: 40px;
    }

    >div {
      padding-top: 15px;
      padding-bottom: 9px;
      margin-left: 0;
      margin-right: 0;
      padding-left: 5px;
      padding-right: 5px;

      &:hover {
        background-color: transparentize($primary, 0.9);
      }
    }
  }
}

.widget-chat-box {
  .card-header {
    i {
      font-size: 17px;
      position: relative;
    }

    .pop-up {
      &:after {
        content: "";
        position: absolute;
        background-color: $danger;
        height: 8px;
        width: 8px;
        border-radius: 50px;
        right: -3px;
      }
    }
  }

  .send-chat {
    $chat-color: #eae9e9;
    position: relative;
    background: lighten($primary, 30%);
    padding: 7px;
    border-radius: 0 10px 10px 10px;
    font-size: 13px;
    margin-bottom: 30px;

    &:before {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px;
      border: 6px solid transparent;
      border-top: 6px solid lighten($primary, 30%);
      border-right: 6px solid lighten($primary, 30%);
      left: -12px;
      top: 0;
    }

    .time {
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  }

  .receive-chat {
    @extend .send-chat;
    background-color: $primary;
    color: #fff;
    border-radius: 10px;

    &:before {
      display: none;
    }

    .time {
      color: #666;
    }
  }

  .rc-10 {
    margin-bottom: 10px;
  }
}

.chat-card {
  .media {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .msg {
    padding: 3px 7px;
    margin: 5px 0;
    display: inline-block;
  }

  .chat-saprator {
    position: relative;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      background-color: var(--bs-border-color);
      height: 2px;
      width: 100%;
      z-index: 1;
    }

    span {
      background-color: #fff;
      color: var(--bs-primary);
      z-index: 99;
      position: relative;
      padding: 0 5px;
      font-style: italic;
    }
  }

  .received-chat {
    .msg {
      background-color: lighten($primary, 30%);
    }
  }

  .send-chat {
    text-align: right;

    .msg {
      text-align: left;
      background-color: lighten($gray-200, 45%);
    }
  }

  .chat-control {
    border-top: 1px solid var(--bs-border-color);
    position: relative;

    .chat-form {
      .form-control {
        border: none;
        margin: 15px 0;

        &:active,
        &:focus,
        &:hover {
          outline: none;
          border: none;
        }
      }

      .chat-btn {
        position: absolute;
        right: 25px;
        top: 22px;
      }
    }
  }

  .msg {
    margin-bottom: 5px;
    display: inline-block;
    padding: 10px;
    position: relative;

    img {
      width: 60px;
      border-radius: 5px;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-right: 10px;
    }
  }

  .received-chat {
    .msg {
      background: lighten($primary, 30%);
      border-radius: 0 5px 5px 5px;

      &:after {
        content: "";
        position: absolute;
        left: -7px;
        top: -7px;
        transform: rotate(45deg);
        border: 7px solid transparent;
        border-bottom-color: lighten($primary, 30%);
      }
    }
  }

  .send-chat {
    text-align: right;

    .msg {
      background: $primary;
      color: #fff;
      border-radius: 5px 0 5px 5px;

      &:after {
        content: "";
        position: absolute;
        right: -7px;
        top: -7px;
        transform: rotate(45deg);
        border: 7px solid transparent;
        border-right-color: $primary;
      }
    }
  }
}

.feed-card {
  h6 {
    margin-top: 7px;
    font-size: 14px;
  }

  .feed-icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 8px 9px;
  }
}

.map-visitor-card {
  .card-header {
    h5 {
      color: #fff;
    }
  }

  .card-footer:not([class*="bg-"]) {
    background: #fff;

    .progress {
      height: 4px;
    }
  }
}

.amount-card {
  color: #fff;

  h4 {
    text-align: center;
  }

  i {
    position: absolute;
    font-size: 25px;
    top: 25px;
    left: 20px;
  }

  p {
    text-align: center;
    position: absolute;
    margin-bottom: 0;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  #amunt-card1,
  #amunt-card2,
  #amunt-card3,
  #amunt-card4 {
    height: 80px !important;
    width: 100%;
  }
}

.mrr-card {
  position: relative;

  .bottom-chart {
    bottom: 0;
  }
}

.bg-patern {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20.5V18H0v-2h20v-2H0v-2h20v-2H0V8h20V6H0V4h20V2H0V0h22v20h2V0h2v20h2V0h2v20h2V0h2v20h2V0h2v20h2v2H20v-1.5zM0 20h2v20H0V20zm4 0h2v20H4V20zm4 0h2v20H8V20zm4 0h2v20h-2V20zm4 0h2v20h-2V20zm4 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2z' fill='%23cccccc' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: center center;
}

.bg-patern-white {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20.5V18H0v-2h20v-2H0v-2h20v-2H0V8h20V6H0V4h20V2H0V0h22v20h2V0h2v20h2V0h2v20h2V0h2v20h2V0h2v20h2v2H20v-1.5zM0 20h2v20H0V20zm4 0h2v20H4V20zm4 0h2v20H8V20zm4 0h2v20h-2V20zm4 0h2v20h-2V20zm4 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2z' fill='%23ffffff' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: center center;
}

// ==================================

.statistics-card-1 {
  .card-body {
    position: relative;
    overflow: hidden;

    >* {
      position: relative;
      z-index: 5;

      &.drp-div {
        z-index: 9;
      }
    }

    .img-bg {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
}

.earning-card {
  .nav {
    background: rgba(255, 255, 255, 0.3);
    border-radius: var(--bs-border-radius);

    .nav-link {
      color: var(--pc-heading-color);
      margin: -8px 0;
      padding-top: 16px;
      padding-bottom: 16px;

      @include media-breakpoint-down(sm) {
        padding: 14px 6px;
      }
    }

    &.nav-pills {

      .nav-link.active,
      .show>.nav-link {
        box-shadow: var(--pc-card-box-shadow);
        background: var(--bs-success-bg-subtle);
        color: var(--bs-success);
      }
    }
  }
}

.table-card .card-body,
.table-body.card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  .table {
    >thead>tr>th {
      border-top: 0;
    }

    tr {

      td,
      th {
        &:first-child {
          padding-left: 25px;

          @include media-breakpoint-down(sm) {
            padding-left: 20px;
          }
        }

        &:last-child {
          padding-right: 25px;

          @include media-breakpoint-down(sm) {
            padding-right: 20px;
          }
        }
      }
    }

    &.without-header {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}

.latest-activity-card {
  .card-body {
    padding-top: 0;

    .latest-update-box {
      position: relative;

      .p-t-20 {
        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
      }

      .p-b-30 {
        @include media-breakpoint-down(sm) {
          padding-bottom: 15px;
        }
      }

      .update-meta {
        z-index: 2;
        min-width: 170px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:after {
          content: '';
          position: absolute;
          border-left: 2px dashed var(--bs-border-color);
          bottom: -10px;
          width: 2px;
          top: -20px;
          right: 32px;
          z-index: 1;
        }

        .update-icon {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          text-align: center;
          font-size: 16px;
          background: var(--bs-card-bg);
          margin-left: 10px;
          position: relative;
          z-index: 5;

          i {
            font-size: 24px;
          }
        }

        @media only screen and (max-width: 575px) {
          z-index: 2;
          min-width: 100%;
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin-bottom: 6px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px;

          &::after {
            display: none;
          }

          .update-icon {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

      .row {
        @include media-breakpoint-down(sm) {
          margin-inline: 0;
        }

        >div {
          @include media-breakpoint-down(sm) {
            padding-inline: 0;
          }
        }

        &:first-child {
          .update-meta {
            border-top: 0;
            padding-top: 0;
          }
        }
      }

    }
  }
}

// ============================
//    10. Widget css end
// ============================