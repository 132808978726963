// ============================
//    13. Button css start
// ============================

.btn {
  font-size: 14px;

  i {
    font-size: 18px;
    &.ph{
      vertical-align: text-bottom;
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &[class*='btn-link-'],
  &[class*='btn-light-'] {
    box-shadow: none;
  }

  &[class*='btn-outline-']:not(:hover) {
    box-shadow: none;
  }

  &.btn-shadow {
    box-shadow: 0 6px 7px -1px rgba(80, 86, 175, 0.3);
  }

  &.btn-sm {
    i {
      font-size: 14px;
    }
  }
  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    width: 40px;
    height: 40px;
    &.avtar-xs {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }
    &.avtar-s {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
    &.avtar-l {
      width: 50px;
      height: 50px;
      font-size: 18px;
    }
    &.avtar-xl {
      width: 60px;
      height: 60px;
      font-size: 20px;
      border-radius: 30px;
    }
  }
}
@each $color, $value in $theme-colors {
  // light color start
  .btn-light-#{$color} {
    background: shift-color($value, $soft-bg-level);
    color: $value;
    border-color: shift-color($value, $soft-bg-level);
    .material-icons-two-tone {
      background-color: $value;
    }

    &:hover {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }

    &.focus,
    &:focus {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }
  }

  .btn-check:active,
  .btn-check:checked {
    + .btn-light-#{$color} {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }
  }
  // light color end
  // link color start
  .btn-link-#{$color} {
    background: transparent;
    color: $value;
    border-color: transparent;
    .material-icons-two-tone {
      background-color: $value;
    }
    &:hover {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }

    &.focus,
    &:focus {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }
  }

  .btn-check:active,
  .btn-check:checked {
    + .btn-link-#{$color} {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }
  }
  // link color end
  // link hover color start
  .btn-link-hover-#{$color} {
    background: transparent;
    color: var(--bs-secondary);
    border-color: transparent;
    .material-icons-two-tone {
      background-color: var(--bs-secondary);
    }
    &:hover {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }

    &.focus,
    &:focus {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }
  }

  .btn-check:active,
  .btn-check:checked {
    + .btn-link-hover-#{$color} {
      background: shift-color($value, $soft-bg-level);
      color: $value;
      border-color: shift-color($value, $soft-bg-level);
    }
  }
  // link hover color end
}

@each $color, $value in $social-colors {
  .btn-light-#{$color} {
    background: shift-color($value, $soft-bg-level);
    color: $value;
    border-color: shift-color($value, $soft-bg-level);
    .material-icons-two-tone {
      background-color: $value;
    }

    &:hover {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }

    &.focus,
    &:focus {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background: $value;
      color: #fff;
      border-color: $value;
      .material-icons-two-tone {
        background-color: #fff;
      }
    }
  }
}
.btn-pc-default {
  &:not(:hover) {
    color: var(--bs-secondary);
  }
}

// ============================
//    13. Button css end
// ============================
