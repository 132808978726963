/**======================================================================
=========================================================================
Template Name: Gradient Able - Bootstrap Admin Template
Author: codedthemes
Support: https://codedthemes.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
body {
  font-feature-settings: "salt";
}

:root {
  --bs-body-bg: #f6f7fb;
  --bs-body-bg-rgb: 246, 247, 251;
  --pc-heading-color: #222;
  --pc-active-background: rgba(255,255,255,.2);
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #39465F;
  --pc-sidebar-color-rgb: 57, 70, 95;
  --pc-sidebar-submenu-border-color: var(--bs-gray-300);
  --pc-sidebar-active-color: #4099ff;
  --pc-sidebar-active-bg: rgba(202,202,202,.3);
  --pc-sidebar-shadow: 2px 0 2.94px 0.06px rgba(4,26,55,.16);
  --pc-sidebar-caption-color: var(--pc-heading-color);
  --pc-sidebar-border: none;
  --pc-header-background: linear-gradient(to right,#4099ff,#73b4ff);
  --pc-header-color: #fff;
  --pc-header-shadow: none;
  --pc-card-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,.16);
  --pc-card-box-hover-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #39465F;
}

[data-pc-sidebar-theme=dark] {
  --pc-sidebar-background: #3b4650;
  --pc-sidebar-color: #FFFFFF;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
}

[data-pc-preset=preset-1] {
  --pc-sidebar-active-color: #4099ff;
  --bs-blue: #4099ff;
  --bs-primary: #4099ff;
  --bs-primary-rgb: 64, 153, 255;
  --bs-primary-light: #ecf5ff;
  --bs-link-color: #4099ff;
  --bs-link-color-rgb: 64, 153, 255;
  --bs-link-hover-color: #337acc;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 64, 153, 255;
}
[data-pc-preset=preset-1] .bg-light-primary {
  background: #ecf5ff;
  color: #4099ff;
}
[data-pc-preset=preset-1] .bg-grd-primary {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}
[data-pc-preset=preset-1] .link-primary {
  color: #4099ff !important;
}
[data-pc-preset=preset-1] .link-primary:hover, [data-pc-preset=preset-1] .link-primary:focus {
  color: #337acc !important;
}
[data-pc-preset=preset-1] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #4099ff;
  --bs-btn-border-color: #4099ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3682d9;
  --bs-btn-hover-border-color: #337acc;
  --bs-btn-focus-shadow-rgb: 93, 168, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #337acc;
  --bs-btn-active-border-color: #3073bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #4099ff;
  --bs-btn-disabled-border-color: #4099ff;
}
[data-pc-preset=preset-1] .btn-link {
  --bs-btn-color: #4099ff;
  --bs-btn-hover-color: #337acc;
  --bs-btn-active-color: #337acc;
}
[data-pc-preset=preset-1] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(64, 153, 255, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-1] .accordion {
  --bs-accordion-btn-focus-border-color: #4099ff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(64, 153, 255, 0.25);
  --bs-accordion-active-color: #4099ff;
  --bs-accordion-active-bg: #ecf5ff;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234099ff'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .alert-primary {
  --bs-alert-color: #265c99;
  --bs-alert-bg: #d9ebff;
  --bs-alert-border-color: #c6e0ff;
  --bs-alert-link-color: #1e4a7a;
}
[data-pc-preset=preset-1] .list-group {
  --bs-list-group-active-bg: #4099ff;
  --bs-list-group-active-border-color: #4099ff;
}
[data-pc-preset=preset-1] .list-group-item-primary {
  color: #265c99;
  background-color: #d9ebff;
}
[data-pc-preset=preset-1] .nav {
  --bs-nav-link-hover-color: #337acc;
}
[data-pc-preset=preset-1] .nav-pills {
  --bs-nav-pills-link-active-bg: #4099ff;
}
[data-pc-preset=preset-1] .pagination {
  --bs-pagination-hover-color: #337acc;
  --bs-pagination-focus-color: #337acc;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(64, 153, 255, 0.25);
  --bs-pagination-active-bg: #4099ff;
  --bs-pagination-active-border-color: #4099ff;
}
[data-pc-preset=preset-1] .progress {
  --bs-progress-bar-bg: #4099ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:checked {
  border-color: #4099ff;
  background-color: #4099ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: #ecf5ff;
  background-color: #ecf5ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%234099ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%234099ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(64, 153, 255, 0.25);
  border-color: #4099ff;
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%234099ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .btn-light-primary {
  background: #ecf5ff;
  color: #4099ff;
  border-color: #ecf5ff;
}
[data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #4099ff;
}
[data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
[data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #4099ff;
  color: #fff;
  border-color: #4099ff;
}
[data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #4099ff;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #4099ff;
}
[data-pc-preset=preset-1] .btn-link-primary:hover {
  background: #ecf5ff;
  color: #4099ff;
  border-color: #ecf5ff;
}
[data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-preset=preset-1] .btn-link-primary:focus {
  background: #ecf5ff;
  color: #4099ff;
  border-color: #ecf5ff;
}
[data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: #ecf5ff;
  color: #4099ff;
  border-color: #ecf5ff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: #ecf5ff;
  color: #4099ff;
  border-color: #ecf5ff;
}
[data-pc-preset=preset-1] .slider-selection {
  background-image: linear-gradient(to bottom, #c0ddff 0, #c0ddff 100%);
}
[data-pc-preset=preset-1] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #a6cfff 0, #a6cfff 100%);
}
[data-pc-preset=preset-1] .swal-button:not([disabled]):hover {
  background-color: #278bff;
}
[data-pc-preset=preset-1] .swal-button:active {
  background-color: #278bff;
}
[data-pc-preset=preset-1] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(64, 153, 255, 0.29);
}
[data-pc-preset=preset-1] .swal-content__input:focus {
  border-color: rgba(64, 153, 255, 0.29);
}
[data-pc-preset=preset-1] .swal-content__textarea:focus {
  border-color: rgba(64, 153, 255, 0.29);
}
[data-pc-preset=preset-1] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(64, 153, 255, 0.4) !important;
}
[data-pc-preset=preset-1] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #a6cfff 0, #a6cfff 100%);
}
[data-pc-preset=preset-1] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #4099ff;
  --bs-table-border-color: #53a3ff;
  --bs-table-striped-bg: #4a9eff;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #53a3ff;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #449bff;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-2] {
  --pc-sidebar-active-color: #6610f2;
  --bs-blue: #6610f2;
  --bs-primary: #6610f2;
  --bs-primary-rgb: 102, 16, 242;
  --bs-primary-light: #f0e7fe;
  --bs-link-color: #6610f2;
  --bs-link-color-rgb: 102, 16, 242;
  --bs-link-hover-color: #520dc2;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 102, 16, 242;
}
[data-pc-preset=preset-2] .bg-light-primary {
  background: #f0e7fe;
  color: #6610f2;
}
[data-pc-preset=preset-2] .bg-grd-primary {
  background: linear-gradient(45deg, #6610f2, #8540f5);
}
[data-pc-preset=preset-2] .link-primary {
  color: #6610f2 !important;
}
[data-pc-preset=preset-2] .link-primary:hover, [data-pc-preset=preset-2] .link-primary:focus {
  color: #520dc2 !important;
}
[data-pc-preset=preset-2] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6610f2;
  --bs-btn-disabled-border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link {
  --bs-btn-color: #6610f2;
  --bs-btn-hover-color: #520dc2;
  --bs-btn-active-color: #520dc2;
}
[data-pc-preset=preset-2] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(102, 16, 242, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-2] .accordion {
  --bs-accordion-btn-focus-border-color: #6610f2;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  --bs-accordion-active-color: #6610f2;
  --bs-accordion-active-bg: #f0e7fe;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236610f2'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .alert-primary {
  --bs-alert-color: #3d0a91;
  --bs-alert-bg: #e0cffc;
  --bs-alert-border-color: #d1b7fb;
  --bs-alert-link-color: #310874;
}
[data-pc-preset=preset-2] .list-group {
  --bs-list-group-active-bg: #6610f2;
  --bs-list-group-active-border-color: #6610f2;
}
[data-pc-preset=preset-2] .list-group-item-primary {
  color: #3d0a91;
  background-color: #e0cffc;
}
[data-pc-preset=preset-2] .nav {
  --bs-nav-link-hover-color: #520dc2;
}
[data-pc-preset=preset-2] .nav-pills {
  --bs-nav-pills-link-active-bg: #6610f2;
}
[data-pc-preset=preset-2] .pagination {
  --bs-pagination-hover-color: #520dc2;
  --bs-pagination-focus-color: #520dc2;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  --bs-pagination-active-bg: #6610f2;
  --bs-pagination-active-border-color: #6610f2;
}
[data-pc-preset=preset-2] .progress {
  --bs-progress-bar-bg: #6610f2;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:checked {
  border-color: #6610f2;
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: #f0e7fe;
  background-color: #f0e7fe;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236610f2' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236610f2'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236610f2'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .btn-light-primary {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-light-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary:hover {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary.focus, [data-pc-preset=preset-2] .btn-light-primary:focus {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-light-primary {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link-primary {
  background: transparent;
  color: #6610f2;
  border-color: transparent;
}
[data-pc-preset=preset-2] .btn-link-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link-primary:hover {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-link-primary.focus, [data-pc-preset=preset-2] .btn-link-primary:focus {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-link-primary.dropdown-toggle {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-link-primary {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .slider-selection {
  background-image: linear-gradient(to bottom, #b389f9 0, #b389f9 100%);
}
[data-pc-preset=preset-2] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #a471f7 0, #a471f7 100%);
}
[data-pc-preset=preset-2] .swal-button:not([disabled]):hover {
  background-color: #5b0cdd;
}
[data-pc-preset=preset-2] .swal-button:active {
  background-color: #5b0cdd;
}
[data-pc-preset=preset-2] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(102, 16, 242, 0.29);
}
[data-pc-preset=preset-2] .swal-content__input:focus {
  border-color: rgba(102, 16, 242, 0.29);
}
[data-pc-preset=preset-2] .swal-content__textarea:focus {
  border-color: rgba(102, 16, 242, 0.29);
}
[data-pc-preset=preset-2] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(102, 16, 242, 0.4) !important;
}
[data-pc-preset=preset-2] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #a471f7 0, #a471f7 100%);
}
[data-pc-preset=preset-2] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #6610f2;
  --bs-table-border-color: #7528f3;
  --bs-table-striped-bg: #6e1cf3;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #7528f3;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #6915f2;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-3] {
  --pc-sidebar-active-color: #673ab7;
  --bs-blue: #673ab7;
  --bs-primary: #673ab7;
  --bs-primary-rgb: 103, 58, 183;
  --bs-primary-light: #f0ebf8;
  --bs-link-color: #673ab7;
  --bs-link-color-rgb: 103, 58, 183;
  --bs-link-hover-color: #522e92;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 103, 58, 183;
}
[data-pc-preset=preset-3] .bg-light-primary {
  background: #f0ebf8;
  color: #673ab7;
}
[data-pc-preset=preset-3] .bg-grd-primary {
  background: linear-gradient(45deg, #673ab7, #8259cb);
}
[data-pc-preset=preset-3] .link-primary {
  color: #673ab7 !important;
}
[data-pc-preset=preset-3] .link-primary:hover, [data-pc-preset=preset-3] .link-primary:focus {
  color: #522e92 !important;
}
[data-pc-preset=preset-3] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #673ab7;
  --bs-btn-border-color: #673ab7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #58319c;
  --bs-btn-hover-border-color: #522e92;
  --bs-btn-focus-shadow-rgb: 126, 88, 194;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #522e92;
  --bs-btn-active-border-color: #4d2c89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #673ab7;
  --bs-btn-disabled-border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link {
  --bs-btn-color: #673ab7;
  --bs-btn-hover-color: #522e92;
  --bs-btn-active-color: #522e92;
}
[data-pc-preset=preset-3] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(103, 58, 183, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-3] .accordion {
  --bs-accordion-btn-focus-border-color: #673ab7;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  --bs-accordion-active-color: #673ab7;
  --bs-accordion-active-bg: #f0ebf8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23673ab7'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .alert-primary {
  --bs-alert-color: #3e236e;
  --bs-alert-bg: #e1d8f1;
  --bs-alert-border-color: #d1c4e9;
  --bs-alert-link-color: #321c58;
}
[data-pc-preset=preset-3] .list-group {
  --bs-list-group-active-bg: #673ab7;
  --bs-list-group-active-border-color: #673ab7;
}
[data-pc-preset=preset-3] .list-group-item-primary {
  color: #3e236e;
  background-color: #e1d8f1;
}
[data-pc-preset=preset-3] .nav {
  --bs-nav-link-hover-color: #522e92;
}
[data-pc-preset=preset-3] .nav-pills {
  --bs-nav-pills-link-active-bg: #673ab7;
}
[data-pc-preset=preset-3] .pagination {
  --bs-pagination-hover-color: #522e92;
  --bs-pagination-focus-color: #522e92;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  --bs-pagination-active-bg: #673ab7;
  --bs-pagination-active-border-color: #673ab7;
}
[data-pc-preset=preset-3] .progress {
  --bs-progress-bar-bg: #673ab7;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:checked {
  border-color: #673ab7;
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: #f0ebf8;
  background-color: #f0ebf8;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23673ab7' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .btn-light-primary {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-light-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary:hover {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary.focus, [data-pc-preset=preset-3] .btn-light-primary:focus {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-light-primary {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link-primary {
  background: transparent;
  color: #673ab7;
  border-color: transparent;
}
[data-pc-preset=preset-3] .btn-link-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link-primary:hover {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-link-primary.focus, [data-pc-preset=preset-3] .btn-link-primary:focus {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-link-primary.dropdown-toggle {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-link-primary {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .slider-selection {
  background-image: linear-gradient(to bottom, #ae94dd 0, #ae94dd 100%);
}
[data-pc-preset=preset-3] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #9f80d7 0, #9f80d7 100%);
}
[data-pc-preset=preset-3] .swal-button:not([disabled]):hover {
  background-color: #5c34a4;
}
[data-pc-preset=preset-3] .swal-button:active {
  background-color: #5c34a4;
}
[data-pc-preset=preset-3] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(103, 58, 183, 0.29);
}
[data-pc-preset=preset-3] .swal-content__input:focus {
  border-color: rgba(103, 58, 183, 0.29);
}
[data-pc-preset=preset-3] .swal-content__textarea:focus {
  border-color: rgba(103, 58, 183, 0.29);
}
[data-pc-preset=preset-3] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(103, 58, 183, 0.4) !important;
}
[data-pc-preset=preset-3] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #9f80d7 0, #9f80d7 100%);
}
[data-pc-preset=preset-3] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #673ab7;
  --bs-table-border-color: #764ebe;
  --bs-table-striped-bg: #6f44bb;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #764ebe;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #6a3eb8;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-4] {
  --pc-sidebar-active-color: #e83e8c;
  --bs-blue: #e83e8c;
  --bs-primary: #e83e8c;
  --bs-primary-rgb: 232, 62, 140;
  --bs-primary-light: #fdecf4;
  --bs-link-color: #e83e8c;
  --bs-link-color-rgb: 232, 62, 140;
  --bs-link-hover-color: #ba3270;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 232, 62, 140;
}
[data-pc-preset=preset-4] .bg-light-primary {
  background: #fdecf4;
  color: #e83e8c;
}
[data-pc-preset=preset-4] .bg-grd-primary {
  background: linear-gradient(45deg, #e83e8c, #ed6ca7);
}
[data-pc-preset=preset-4] .link-primary {
  color: #e83e8c !important;
}
[data-pc-preset=preset-4] .link-primary:hover, [data-pc-preset=preset-4] .link-primary:focus {
  color: #ba3270 !important;
}
[data-pc-preset=preset-4] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e83e8c;
  --bs-btn-border-color: #e83e8c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c53577;
  --bs-btn-hover-border-color: #ba3270;
  --bs-btn-focus-shadow-rgb: 235, 91, 157;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ba3270;
  --bs-btn-active-border-color: #ae2f69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e83e8c;
  --bs-btn-disabled-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link {
  --bs-btn-color: #e83e8c;
  --bs-btn-hover-color: #ba3270;
  --bs-btn-active-color: #ba3270;
}
[data-pc-preset=preset-4] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(232, 62, 140, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-4] .accordion {
  --bs-accordion-btn-focus-border-color: #e83e8c;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  --bs-accordion-active-color: #e83e8c;
  --bs-accordion-active-bg: #fdecf4;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e83e8c'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .alert-primary {
  --bs-alert-color: #8b2554;
  --bs-alert-bg: #fad8e8;
  --bs-alert-border-color: #f8c5dd;
  --bs-alert-link-color: #6f1e43;
}
[data-pc-preset=preset-4] .list-group {
  --bs-list-group-active-bg: #e83e8c;
  --bs-list-group-active-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .list-group-item-primary {
  color: #8b2554;
  background-color: #fad8e8;
}
[data-pc-preset=preset-4] .nav {
  --bs-nav-link-hover-color: #ba3270;
}
[data-pc-preset=preset-4] .nav-pills {
  --bs-nav-pills-link-active-bg: #e83e8c;
}
[data-pc-preset=preset-4] .pagination {
  --bs-pagination-hover-color: #ba3270;
  --bs-pagination-focus-color: #ba3270;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  --bs-pagination-active-bg: #e83e8c;
  --bs-pagination-active-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .progress {
  --bs-progress-bar-bg: #e83e8c;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:checked {
  border-color: #e83e8c;
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fdecf4;
  background-color: #fdecf4;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23e83e8c' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23e83e8c'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e83e8c'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .btn-light-primary {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-light-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary:hover {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary.focus, [data-pc-preset=preset-4] .btn-light-primary:focus {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-light-primary {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link-primary {
  background: transparent;
  color: #e83e8c;
  border-color: transparent;
}
[data-pc-preset=preset-4] .btn-link-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link-primary:hover {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-link-primary.focus, [data-pc-preset=preset-4] .btn-link-primary:focus {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-link-primary.dropdown-toggle {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-link-primary {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .slider-selection {
  background-image: linear-gradient(to bottom, #f6b0d0 0, #f6b0d0 100%);
}
[data-pc-preset=preset-4] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #f399c2 0, #f399c2 100%);
}
[data-pc-preset=preset-4] .swal-button:not([disabled]):hover {
  background-color: #e5277e;
}
[data-pc-preset=preset-4] .swal-button:active {
  background-color: #e5277e;
}
[data-pc-preset=preset-4] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(232, 62, 140, 0.29);
}
[data-pc-preset=preset-4] .swal-content__input:focus {
  border-color: rgba(232, 62, 140, 0.29);
}
[data-pc-preset=preset-4] .swal-content__textarea:focus {
  border-color: rgba(232, 62, 140, 0.29);
}
[data-pc-preset=preset-4] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(232, 62, 140, 0.4) !important;
}
[data-pc-preset=preset-4] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #f399c2 0, #f399c2 100%);
}
[data-pc-preset=preset-4] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #e83e8c;
  --bs-table-border-color: #ea5198;
  --bs-table-striped-bg: #e94892;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #ea5198;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #e8428e;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-5] {
  --pc-sidebar-active-color: #ff5370;
  --bs-blue: #ff5370;
  --bs-primary: #ff5370;
  --bs-primary-rgb: 255, 83, 112;
  --bs-primary-light: #ffeef1;
  --bs-link-color: #ff5370;
  --bs-link-color-rgb: 255, 83, 112;
  --bs-link-hover-color: #cc425a;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 255, 83, 112;
}
[data-pc-preset=preset-5] .bg-light-primary {
  background: #ffeef1;
  color: #ff5370;
}
[data-pc-preset=preset-5] .bg-grd-primary {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}
[data-pc-preset=preset-5] .link-primary {
  color: #ff5370 !important;
}
[data-pc-preset=preset-5] .link-primary:hover, [data-pc-preset=preset-5] .link-primary:focus {
  color: #cc425a !important;
}
[data-pc-preset=preset-5] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ff5370;
  --bs-btn-border-color: #ff5370;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d9475f;
  --bs-btn-hover-border-color: #cc425a;
  --bs-btn-focus-shadow-rgb: 255, 109, 133;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc425a;
  --bs-btn-active-border-color: #bf3e54;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ff5370;
  --bs-btn-disabled-border-color: #ff5370;
}
[data-pc-preset=preset-5] .btn-link {
  --bs-btn-color: #ff5370;
  --bs-btn-hover-color: #cc425a;
  --bs-btn-active-color: #cc425a;
}
[data-pc-preset=preset-5] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(255, 83, 112, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-5] .accordion {
  --bs-accordion-btn-focus-border-color: #ff5370;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(255, 83, 112, 0.25);
  --bs-accordion-active-color: #ff5370;
  --bs-accordion-active-bg: #ffeef1;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff5370'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .alert-primary {
  --bs-alert-color: #993243;
  --bs-alert-bg: #ffdde2;
  --bs-alert-border-color: #ffcbd4;
  --bs-alert-link-color: #7a2836;
}
[data-pc-preset=preset-5] .list-group {
  --bs-list-group-active-bg: #ff5370;
  --bs-list-group-active-border-color: #ff5370;
}
[data-pc-preset=preset-5] .list-group-item-primary {
  color: #993243;
  background-color: #ffdde2;
}
[data-pc-preset=preset-5] .nav {
  --bs-nav-link-hover-color: #cc425a;
}
[data-pc-preset=preset-5] .nav-pills {
  --bs-nav-pills-link-active-bg: #ff5370;
}
[data-pc-preset=preset-5] .pagination {
  --bs-pagination-hover-color: #cc425a;
  --bs-pagination-focus-color: #cc425a;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(255, 83, 112, 0.25);
  --bs-pagination-active-bg: #ff5370;
  --bs-pagination-active-border-color: #ff5370;
}
[data-pc-preset=preset-5] .progress {
  --bs-progress-bar-bg: #ff5370;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:checked {
  border-color: #ff5370;
  background-color: #ff5370;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: #ffeef1;
  background-color: #ffeef1;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ff5370' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ff5370'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(255, 83, 112, 0.25);
  border-color: #ff5370;
}
[data-pc-preset=preset-5] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff5370'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .btn-light-primary {
  background: #ffeef1;
  color: #ff5370;
  border-color: #ffeef1;
}
[data-pc-preset=preset-5] .btn-light-primary .material-icons-two-tone {
  background-color: #ff5370;
}
[data-pc-preset=preset-5] .btn-light-primary:hover {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
[data-pc-preset=preset-5] .btn-light-primary.focus, [data-pc-preset=preset-5] .btn-light-primary:focus {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
[data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-light-primary {
  background: #ff5370;
  color: #fff;
  border-color: #ff5370;
}
[data-pc-preset=preset-5] .btn-link-primary {
  background: transparent;
  color: #ff5370;
  border-color: transparent;
}
[data-pc-preset=preset-5] .btn-link-primary .material-icons-two-tone {
  background-color: #ff5370;
}
[data-pc-preset=preset-5] .btn-link-primary:hover {
  background: #ffeef1;
  color: #ff5370;
  border-color: #ffeef1;
}
[data-pc-preset=preset-5] .btn-link-primary.focus, [data-pc-preset=preset-5] .btn-link-primary:focus {
  background: #ffeef1;
  color: #ff5370;
  border-color: #ffeef1;
}
[data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-link-primary.dropdown-toggle {
  background: #ffeef1;
  color: #ff5370;
  border-color: #ffeef1;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-link-primary {
  background: #ffeef1;
  color: #ff5370;
  border-color: #ffeef1;
}
[data-pc-preset=preset-5] .slider-selection {
  background-image: linear-gradient(to bottom, #ffd3da 0, #ffd3da 100%);
}
[data-pc-preset=preset-5] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #ffb9c5 0, #ffb9c5 100%);
}
[data-pc-preset=preset-5] .swal-button:not([disabled]):hover {
  background-color: #ff3a5b;
}
[data-pc-preset=preset-5] .swal-button:active {
  background-color: #ff3a5b;
}
[data-pc-preset=preset-5] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(255, 83, 112, 0.29);
}
[data-pc-preset=preset-5] .swal-content__input:focus {
  border-color: rgba(255, 83, 112, 0.29);
}
[data-pc-preset=preset-5] .swal-content__textarea:focus {
  border-color: rgba(255, 83, 112, 0.29);
}
[data-pc-preset=preset-5] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(255, 83, 112, 0.4) !important;
}
[data-pc-preset=preset-5] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #ffb9c5 0, #ffb9c5 100%);
}
[data-pc-preset=preset-5] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #ff5370;
  --bs-table-border-color: #ff647e;
  --bs-table-striped-bg: #ff5c77;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #ff647e;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #ff5673;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-6] {
  --pc-sidebar-active-color: #fd7e14;
  --bs-blue: #fd7e14;
  --bs-primary: #fd7e14;
  --bs-primary-rgb: 253, 126, 20;
  --bs-primary-light: #fff2e8;
  --bs-link-color: #fd7e14;
  --bs-link-color-rgb: 253, 126, 20;
  --bs-link-hover-color: #ca6510;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 253, 126, 20;
}
[data-pc-preset=preset-6] .bg-light-primary {
  background: #fff2e8;
  color: #fd7e14;
}
[data-pc-preset=preset-6] .bg-grd-primary {
  background: linear-gradient(45deg, #fd7e14, #fd9a47);
}
[data-pc-preset=preset-6] .link-primary {
  color: #fd7e14 !important;
}
[data-pc-preset=preset-6] .link-primary:hover, [data-pc-preset=preset-6] .link-primary:focus {
  color: #ca6510 !important;
}
[data-pc-preset=preset-6] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d76b11;
  --bs-btn-hover-border-color: #ca6510;
  --bs-btn-focus-shadow-rgb: 253, 145, 55;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ca6510;
  --bs-btn-active-border-color: #be5f0f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link {
  --bs-btn-color: #fd7e14;
  --bs-btn-hover-color: #ca6510;
  --bs-btn-active-color: #ca6510;
}
[data-pc-preset=preset-6] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-6] .accordion {
  --bs-accordion-btn-focus-border-color: #fd7e14;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  --bs-accordion-active-color: #fd7e14;
  --bs-accordion-active-bg: #fff2e8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd7e14'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .alert-primary {
  --bs-alert-color: #984c0c;
  --bs-alert-bg: #ffe5d0;
  --bs-alert-border-color: #fed8b9;
  --bs-alert-link-color: #7a3d0a;
}
[data-pc-preset=preset-6] .list-group {
  --bs-list-group-active-bg: #fd7e14;
  --bs-list-group-active-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .list-group-item-primary {
  color: #984c0c;
  background-color: #ffe5d0;
}
[data-pc-preset=preset-6] .nav {
  --bs-nav-link-hover-color: #ca6510;
}
[data-pc-preset=preset-6] .nav-pills {
  --bs-nav-pills-link-active-bg: #fd7e14;
}
[data-pc-preset=preset-6] .pagination {
  --bs-pagination-hover-color: #ca6510;
  --bs-pagination-focus-color: #ca6510;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  --bs-pagination-active-bg: #fd7e14;
  --bs-pagination-active-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .progress {
  --bs-progress-bar-bg: #fd7e14;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:checked {
  border-color: #fd7e14;
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fff2e8;
  background-color: #fff2e8;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fd7e14' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fd7e14'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fd7e14'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .btn-light-primary {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-light-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary:hover {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary.focus, [data-pc-preset=preset-6] .btn-light-primary:focus {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-light-primary {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link-primary {
  background: transparent;
  color: #fd7e14;
  border-color: transparent;
}
[data-pc-preset=preset-6] .btn-link-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link-primary:hover {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-link-primary.focus, [data-pc-preset=preset-6] .btn-link-primary:focus {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-link-primary.dropdown-toggle {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-link-primary {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .slider-selection {
  background-image: linear-gradient(to bottom, #fec392 0, #fec392 100%);
}
[data-pc-preset=preset-6] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #feb679 0, #feb679 100%);
}
[data-pc-preset=preset-6] .swal-button:not([disabled]):hover {
  background-color: #f57102;
}
[data-pc-preset=preset-6] .swal-button:active {
  background-color: #f57102;
}
[data-pc-preset=preset-6] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(253, 126, 20, 0.29);
}
[data-pc-preset=preset-6] .swal-content__input:focus {
  border-color: rgba(253, 126, 20, 0.29);
}
[data-pc-preset=preset-6] .swal-content__textarea:focus {
  border-color: rgba(253, 126, 20, 0.29);
}
[data-pc-preset=preset-6] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(253, 126, 20, 0.4) !important;
}
[data-pc-preset=preset-6] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #feb679 0, #feb679 100%);
}
[data-pc-preset=preset-6] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #fd7e14;
  --bs-table-border-color: #fd8b2c;
  --bs-table-striped-bg: #fd8420;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #fd8b2c;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #fd8119;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-7] {
  --pc-sidebar-active-color: #ffb64d;
  --bs-blue: #ffb64d;
  --bs-primary: #ffb64d;
  --bs-primary-rgb: 255, 182, 77;
  --bs-primary-light: #fff8ed;
  --bs-link-color: #ffb64d;
  --bs-link-color-rgb: 255, 182, 77;
  --bs-link-hover-color: #cc923e;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 255, 182, 77;
}
[data-pc-preset=preset-7] .bg-light-primary {
  background: #fff8ed;
  color: #ffb64d;
}
[data-pc-preset=preset-7] .bg-grd-primary {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}
[data-pc-preset=preset-7] .link-primary {
  color: #ffb64d !important;
}
[data-pc-preset=preset-7] .link-primary:hover, [data-pc-preset=preset-7] .link-primary:focus {
  color: #cc923e !important;
}
[data-pc-preset=preset-7] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ffb64d;
  --bs-btn-border-color: #ffb64d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d99b41;
  --bs-btn-hover-border-color: #cc923e;
  --bs-btn-focus-shadow-rgb: 255, 193, 104;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #cc923e;
  --bs-btn-active-border-color: #bf893a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ffb64d;
  --bs-btn-disabled-border-color: #ffb64d;
}
[data-pc-preset=preset-7] .btn-link {
  --bs-btn-color: #ffb64d;
  --bs-btn-hover-color: #cc923e;
  --bs-btn-active-color: #cc923e;
}
[data-pc-preset=preset-7] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(255, 182, 77, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-7] .accordion {
  --bs-accordion-btn-focus-border-color: #ffb64d;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(255, 182, 77, 0.25);
  --bs-accordion-active-color: #ffb64d;
  --bs-accordion-active-bg: #fff8ed;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffb64d'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .alert-primary {
  --bs-alert-color: #996d2e;
  --bs-alert-bg: #fff0db;
  --bs-alert-border-color: #ffe9ca;
  --bs-alert-link-color: #7a5725;
}
[data-pc-preset=preset-7] .list-group {
  --bs-list-group-active-bg: #ffb64d;
  --bs-list-group-active-border-color: #ffb64d;
}
[data-pc-preset=preset-7] .list-group-item-primary {
  color: #996d2e;
  background-color: #fff0db;
}
[data-pc-preset=preset-7] .nav {
  --bs-nav-link-hover-color: #cc923e;
}
[data-pc-preset=preset-7] .nav-pills {
  --bs-nav-pills-link-active-bg: #ffb64d;
}
[data-pc-preset=preset-7] .pagination {
  --bs-pagination-hover-color: #cc923e;
  --bs-pagination-focus-color: #cc923e;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(255, 182, 77, 0.25);
  --bs-pagination-active-bg: #ffb64d;
  --bs-pagination-active-border-color: #ffb64d;
}
[data-pc-preset=preset-7] .progress {
  --bs-progress-bar-bg: #ffb64d;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:checked {
  border-color: #ffb64d;
  background-color: #ffb64d;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fff8ed;
  background-color: #fff8ed;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffb64d' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffb64d'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(255, 182, 77, 0.25);
  border-color: #ffb64d;
}
[data-pc-preset=preset-7] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffb64d'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .btn-light-primary {
  background: #fff8ed;
  color: #ffb64d;
  border-color: #fff8ed;
}
[data-pc-preset=preset-7] .btn-light-primary .material-icons-two-tone {
  background-color: #ffb64d;
}
[data-pc-preset=preset-7] .btn-light-primary:hover {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
[data-pc-preset=preset-7] .btn-light-primary.focus, [data-pc-preset=preset-7] .btn-light-primary:focus {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
[data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-light-primary {
  background: #ffb64d;
  color: #fff;
  border-color: #ffb64d;
}
[data-pc-preset=preset-7] .btn-link-primary {
  background: transparent;
  color: #ffb64d;
  border-color: transparent;
}
[data-pc-preset=preset-7] .btn-link-primary .material-icons-two-tone {
  background-color: #ffb64d;
}
[data-pc-preset=preset-7] .btn-link-primary:hover {
  background: #fff8ed;
  color: #ffb64d;
  border-color: #fff8ed;
}
[data-pc-preset=preset-7] .btn-link-primary.focus, [data-pc-preset=preset-7] .btn-link-primary:focus {
  background: #fff8ed;
  color: #ffb64d;
  border-color: #fff8ed;
}
[data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-link-primary.dropdown-toggle {
  background: #fff8ed;
  color: #ffb64d;
  border-color: #fff8ed;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-link-primary {
  background: #fff8ed;
  color: #ffb64d;
  border-color: #fff8ed;
}
[data-pc-preset=preset-7] .slider-selection {
  background-image: linear-gradient(to bottom, #ffeacd 0, #ffeacd 100%);
}
[data-pc-preset=preset-7] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #ffe0b3 0, #ffe0b3 100%);
}
[data-pc-preset=preset-7] .swal-button:not([disabled]):hover {
  background-color: #ffac34;
}
[data-pc-preset=preset-7] .swal-button:active {
  background-color: #ffac34;
}
[data-pc-preset=preset-7] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(255, 182, 77, 0.29);
}
[data-pc-preset=preset-7] .swal-content__input:focus {
  border-color: rgba(255, 182, 77, 0.29);
}
[data-pc-preset=preset-7] .swal-content__textarea:focus {
  border-color: rgba(255, 182, 77, 0.29);
}
[data-pc-preset=preset-7] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(255, 182, 77, 0.4) !important;
}
[data-pc-preset=preset-7] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #ffe0b3 0, #ffe0b3 100%);
}
[data-pc-preset=preset-7] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #ffb64d;
  --bs-table-border-color: #ffbd5f;
  --bs-table-striped-bg: #ffba56;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #ffbd5f;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #ffb751;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-8] {
  --pc-sidebar-active-color: #2ed8b6;
  --bs-blue: #2ed8b6;
  --bs-primary: #2ed8b6;
  --bs-primary-rgb: 46, 216, 182;
  --bs-primary-light: #eafbf8;
  --bs-link-color: #2ed8b6;
  --bs-link-color-rgb: 46, 216, 182;
  --bs-link-hover-color: #25ad92;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 46, 216, 182;
}
[data-pc-preset=preset-8] .bg-light-primary {
  background: #eafbf8;
  color: #2ed8b6;
}
[data-pc-preset=preset-8] .bg-grd-primary {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}
[data-pc-preset=preset-8] .link-primary {
  color: #2ed8b6 !important;
}
[data-pc-preset=preset-8] .link-primary:hover, [data-pc-preset=preset-8] .link-primary:focus {
  color: #25ad92 !important;
}
[data-pc-preset=preset-8] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2ed8b6;
  --bs-btn-border-color: #2ed8b6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #27b89b;
  --bs-btn-hover-border-color: #25ad92;
  --bs-btn-focus-shadow-rgb: 77, 222, 193;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #25ad92;
  --bs-btn-active-border-color: #23a289;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2ed8b6;
  --bs-btn-disabled-border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .btn-link {
  --bs-btn-color: #2ed8b6;
  --bs-btn-hover-color: #25ad92;
  --bs-btn-active-color: #25ad92;
}
[data-pc-preset=preset-8] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(46, 216, 182, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-8] .accordion {
  --bs-accordion-btn-focus-border-color: #2ed8b6;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(46, 216, 182, 0.25);
  --bs-accordion-active-color: #2ed8b6;
  --bs-accordion-active-bg: #eafbf8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232ed8b6'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .alert-primary {
  --bs-alert-color: #1c826d;
  --bs-alert-bg: #d5f7f0;
  --bs-alert-border-color: #c0f3e9;
  --bs-alert-link-color: #166857;
}
[data-pc-preset=preset-8] .list-group {
  --bs-list-group-active-bg: #2ed8b6;
  --bs-list-group-active-border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .list-group-item-primary {
  color: #1c826d;
  background-color: #d5f7f0;
}
[data-pc-preset=preset-8] .nav {
  --bs-nav-link-hover-color: #25ad92;
}
[data-pc-preset=preset-8] .nav-pills {
  --bs-nav-pills-link-active-bg: #2ed8b6;
}
[data-pc-preset=preset-8] .pagination {
  --bs-pagination-hover-color: #25ad92;
  --bs-pagination-focus-color: #25ad92;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(46, 216, 182, 0.25);
  --bs-pagination-active-bg: #2ed8b6;
  --bs-pagination-active-border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .progress {
  --bs-progress-bar-bg: #2ed8b6;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:checked {
  border-color: #2ed8b6;
  background-color: #2ed8b6;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked {
  border-color: #eafbf8;
  background-color: #eafbf8;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232ed8b6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232ed8b6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(46, 216, 182, 0.25);
  border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232ed8b6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .btn-light-primary {
  background: #eafbf8;
  color: #2ed8b6;
  border-color: #eafbf8;
}
[data-pc-preset=preset-8] .btn-light-primary .material-icons-two-tone {
  background-color: #2ed8b6;
}
[data-pc-preset=preset-8] .btn-light-primary:hover {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .btn-light-primary.focus, [data-pc-preset=preset-8] .btn-light-primary:focus {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-light-primary.dropdown-toggle {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-light-primary {
  background: #2ed8b6;
  color: #fff;
  border-color: #2ed8b6;
}
[data-pc-preset=preset-8] .btn-link-primary {
  background: transparent;
  color: #2ed8b6;
  border-color: transparent;
}
[data-pc-preset=preset-8] .btn-link-primary .material-icons-two-tone {
  background-color: #2ed8b6;
}
[data-pc-preset=preset-8] .btn-link-primary:hover {
  background: #eafbf8;
  color: #2ed8b6;
  border-color: #eafbf8;
}
[data-pc-preset=preset-8] .btn-link-primary.focus, [data-pc-preset=preset-8] .btn-link-primary:focus {
  background: #eafbf8;
  color: #2ed8b6;
  border-color: #eafbf8;
}
[data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-link-primary.dropdown-toggle {
  background: #eafbf8;
  color: #2ed8b6;
  border-color: #eafbf8;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-link-primary {
  background: #eafbf8;
  color: #2ed8b6;
  border-color: #eafbf8;
}
[data-pc-preset=preset-8] .slider-selection {
  background-image: linear-gradient(to bottom, #99ecdc 0, #99ecdc 100%);
}
[data-pc-preset=preset-8] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #84e8d4 0, #84e8d4 100%);
}
[data-pc-preset=preset-8] .swal-button:not([disabled]):hover {
  background-color: #25c7a7;
}
[data-pc-preset=preset-8] .swal-button:active {
  background-color: #25c7a7;
}
[data-pc-preset=preset-8] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(46, 216, 182, 0.29);
}
[data-pc-preset=preset-8] .swal-content__input:focus {
  border-color: rgba(46, 216, 182, 0.29);
}
[data-pc-preset=preset-8] .swal-content__textarea:focus {
  border-color: rgba(46, 216, 182, 0.29);
}
[data-pc-preset=preset-8] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(46, 216, 182, 0.4) !important;
}
[data-pc-preset=preset-8] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #84e8d4 0, #84e8d4 100%);
}
[data-pc-preset=preset-8] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #2ed8b6;
  --bs-table-border-color: #43dcbd;
  --bs-table-striped-bg: #38daba;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #43dcbd;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #32d9b7;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-9] {
  --pc-sidebar-active-color: #008080;
  --bs-blue: #008080;
  --bs-primary: #008080;
  --bs-primary-rgb: 0, 128, 128;
  --bs-primary-light: #e6f2f2;
  --bs-link-color: #008080;
  --bs-link-color-rgb: 0, 128, 128;
  --bs-link-hover-color: #006666;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 0, 128, 128;
}
[data-pc-preset=preset-9] .bg-light-primary {
  background: #e6f2f2;
  color: #008080;
}
[data-pc-preset=preset-9] .bg-grd-primary {
  background: linear-gradient(45deg, #008080, #00b3b3);
}
[data-pc-preset=preset-9] .link-primary {
  color: #008080 !important;
}
[data-pc-preset=preset-9] .link-primary:hover, [data-pc-preset=preset-9] .link-primary:focus {
  color: #006666 !important;
}
[data-pc-preset=preset-9] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #008080;
  --bs-btn-border-color: #008080;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #006d6d;
  --bs-btn-hover-border-color: #006666;
  --bs-btn-focus-shadow-rgb: 38, 147, 147;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #006666;
  --bs-btn-active-border-color: #006060;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #008080;
  --bs-btn-disabled-border-color: #008080;
}
[data-pc-preset=preset-9] .btn-link {
  --bs-btn-color: #008080;
  --bs-btn-hover-color: #006666;
  --bs-btn-active-color: #006666;
}
[data-pc-preset=preset-9] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(0, 128, 128, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-9] .accordion {
  --bs-accordion-btn-focus-border-color: #008080;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  --bs-accordion-active-color: #008080;
  --bs-accordion-active-bg: #e6f2f2;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008080'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .alert-primary {
  --bs-alert-color: #004d4d;
  --bs-alert-bg: #cce6e6;
  --bs-alert-border-color: #b3d9d9;
  --bs-alert-link-color: #003e3e;
}
[data-pc-preset=preset-9] .list-group {
  --bs-list-group-active-bg: #008080;
  --bs-list-group-active-border-color: #008080;
}
[data-pc-preset=preset-9] .list-group-item-primary {
  color: #004d4d;
  background-color: #cce6e6;
}
[data-pc-preset=preset-9] .nav {
  --bs-nav-link-hover-color: #006666;
}
[data-pc-preset=preset-9] .nav-pills {
  --bs-nav-pills-link-active-bg: #008080;
}
[data-pc-preset=preset-9] .pagination {
  --bs-pagination-hover-color: #006666;
  --bs-pagination-focus-color: #006666;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  --bs-pagination-active-bg: #008080;
  --bs-pagination-active-border-color: #008080;
}
[data-pc-preset=preset-9] .progress {
  --bs-progress-bar-bg: #008080;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:checked {
  border-color: #008080;
  background-color: #008080;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f2f2;
  background-color: #e6f2f2;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23008080' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23008080'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  border-color: #008080;
}
[data-pc-preset=preset-9] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23008080'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .btn-light-primary {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-light-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary:hover {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary.focus, [data-pc-preset=preset-9] .btn-light-primary:focus {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-light-primary.dropdown-toggle {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-light-primary {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-link-primary {
  background: transparent;
  color: #008080;
  border-color: transparent;
}
[data-pc-preset=preset-9] .btn-link-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-preset=preset-9] .btn-link-primary:hover {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-link-primary.focus, [data-pc-preset=preset-9] .btn-link-primary:focus {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-link-primary.dropdown-toggle {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-link-primary {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .slider-selection {
  background-image: linear-gradient(to bottom, #01ffff 0, #01ffff 100%);
}
[data-pc-preset=preset-9] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #00e6e6 0, #00e6e6 100%);
}
[data-pc-preset=preset-9] .swal-button:not([disabled]):hover {
  background-color: #006767;
}
[data-pc-preset=preset-9] .swal-button:active {
  background-color: #006767;
}
[data-pc-preset=preset-9] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 128, 128, 0.29);
}
[data-pc-preset=preset-9] .swal-content__input:focus {
  border-color: rgba(0, 128, 128, 0.29);
}
[data-pc-preset=preset-9] .swal-content__textarea:focus {
  border-color: rgba(0, 128, 128, 0.29);
}
[data-pc-preset=preset-9] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 128, 128, 0.4) !important;
}
[data-pc-preset=preset-9] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #00e6e6 0, #00e6e6 100%);
}
[data-pc-preset=preset-9] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #008080;
  --bs-table-border-color: #1a8d8d;
  --bs-table-striped-bg: #0d8686;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1a8d8d;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #058383;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-10] {
  --pc-sidebar-active-color: #00bcd4;
  --bs-blue: #00bcd4;
  --bs-primary: #00bcd4;
  --bs-primary-rgb: 0, 188, 212;
  --bs-primary-light: #e6f8fb;
  --bs-link-color: #00bcd4;
  --bs-link-color-rgb: 0, 188, 212;
  --bs-link-hover-color: #0096aa;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 0, 188, 212;
}
[data-pc-preset=preset-10] .bg-light-primary {
  background: #e6f8fb;
  color: #00bcd4;
}
[data-pc-preset=preset-10] .bg-grd-primary {
  background: linear-gradient(45deg, #00bcd4, #08e3ff);
}
[data-pc-preset=preset-10] .link-primary {
  color: #00bcd4 !important;
}
[data-pc-preset=preset-10] .link-primary:hover, [data-pc-preset=preset-10] .link-primary:focus {
  color: #0096aa !important;
}
[data-pc-preset=preset-10] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #00bcd4;
  --bs-btn-border-color: #00bcd4;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #00a0b4;
  --bs-btn-hover-border-color: #0096aa;
  --bs-btn-focus-shadow-rgb: 38, 198, 218;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0096aa;
  --bs-btn-active-border-color: #008d9f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #00bcd4;
  --bs-btn-disabled-border-color: #00bcd4;
}
[data-pc-preset=preset-10] .btn-link {
  --bs-btn-color: #00bcd4;
  --bs-btn-hover-color: #0096aa;
  --bs-btn-active-color: #0096aa;
}
[data-pc-preset=preset-10] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(0, 188, 212, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-10] .accordion {
  --bs-accordion-btn-focus-border-color: #00bcd4;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
  --bs-accordion-active-color: #00bcd4;
  --bs-accordion-active-bg: #e6f8fb;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300bcd4'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .alert-primary {
  --bs-alert-color: #00717f;
  --bs-alert-bg: #ccf2f6;
  --bs-alert-border-color: #b3ebf2;
  --bs-alert-link-color: #005a66;
}
[data-pc-preset=preset-10] .list-group {
  --bs-list-group-active-bg: #00bcd4;
  --bs-list-group-active-border-color: #00bcd4;
}
[data-pc-preset=preset-10] .list-group-item-primary {
  color: #00717f;
  background-color: #ccf2f6;
}
[data-pc-preset=preset-10] .nav {
  --bs-nav-link-hover-color: #0096aa;
}
[data-pc-preset=preset-10] .nav-pills {
  --bs-nav-pills-link-active-bg: #00bcd4;
}
[data-pc-preset=preset-10] .pagination {
  --bs-pagination-hover-color: #0096aa;
  --bs-pagination-focus-color: #0096aa;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
  --bs-pagination-active-bg: #00bcd4;
  --bs-pagination-active-border-color: #00bcd4;
}
[data-pc-preset=preset-10] .progress {
  --bs-progress-bar-bg: #00bcd4;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-primary:checked {
  border-color: #00bcd4;
  background-color: #00bcd4;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f8fb;
  background-color: #e6f8fb;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2300bcd4' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2300bcd4'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-10] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
  border-color: #00bcd4;
}
[data-pc-preset=preset-10] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300bcd4'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .btn-light-primary {
  background: #e6f8fb;
  color: #00bcd4;
  border-color: #e6f8fb;
}
[data-pc-preset=preset-10] .btn-light-primary .material-icons-two-tone {
  background-color: #00bcd4;
}
[data-pc-preset=preset-10] .btn-light-primary:hover {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
[data-pc-preset=preset-10] .btn-light-primary.focus, [data-pc-preset=preset-10] .btn-light-primary:focus {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
[data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-10] .btn-light-primary.dropdown-toggle {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
[data-pc-preset=preset-10] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-10] .btn-check:checked + .btn-light-primary {
  background: #00bcd4;
  color: #fff;
  border-color: #00bcd4;
}
[data-pc-preset=preset-10] .btn-link-primary {
  background: transparent;
  color: #00bcd4;
  border-color: transparent;
}
[data-pc-preset=preset-10] .btn-link-primary .material-icons-two-tone {
  background-color: #00bcd4;
}
[data-pc-preset=preset-10] .btn-link-primary:hover {
  background: #e6f8fb;
  color: #00bcd4;
  border-color: #e6f8fb;
}
[data-pc-preset=preset-10] .btn-link-primary.focus, [data-pc-preset=preset-10] .btn-link-primary:focus {
  background: #e6f8fb;
  color: #00bcd4;
  border-color: #e6f8fb;
}
[data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-10] .btn-link-primary.dropdown-toggle {
  background: #e6f8fb;
  color: #00bcd4;
  border-color: #e6f8fb;
}
[data-pc-preset=preset-10] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-10] .btn-check:checked + .btn-link-primary {
  background: #e6f8fb;
  color: #00bcd4;
  border-color: #e6f8fb;
}
[data-pc-preset=preset-10] .slider-selection {
  background-image: linear-gradient(to bottom, #55ecff 0, #55ecff 100%);
}
[data-pc-preset=preset-10] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #3be9ff 0, #3be9ff 100%);
}
[data-pc-preset=preset-10] .swal-button:not([disabled]):hover {
  background-color: #00a5bb;
}
[data-pc-preset=preset-10] .swal-button:active {
  background-color: #00a5bb;
}
[data-pc-preset=preset-10] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 188, 212, 0.29);
}
[data-pc-preset=preset-10] .swal-content__input:focus {
  border-color: rgba(0, 188, 212, 0.29);
}
[data-pc-preset=preset-10] .swal-content__textarea:focus {
  border-color: rgba(0, 188, 212, 0.29);
}
[data-pc-preset=preset-10] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 188, 212, 0.4) !important;
}
[data-pc-preset=preset-10] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #3be9ff 0, #3be9ff 100%);
}
[data-pc-preset=preset-10] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #00bcd4;
  --bs-table-border-color: #1ac3d8;
  --bs-table-striped-bg: #0dbfd6;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1ac3d8;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #05bdd5;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-header=header-1] {
  --pc-header-background: linear-gradient(to right, #4099ff, #73b4ff);
}

[data-pc-header=header-2] {
  --pc-header-background: linear-gradient(to right, #FF5370, #f48fb1);
}

[data-pc-header=header-3] {
  --pc-header-background: linear-gradient(to right, #4099ff, #6E7FF3);
}

[data-pc-header=header-4] {
  --pc-header-background: linear-gradient(to right, #38a9f9, #43e9b3);
}

[data-pc-header=header-5] {
  --pc-header-background: linear-gradient(to right, #3b4650, #647688);
}

[data-pc-header=header-6] {
  --pc-header-background: linear-gradient(to right, #ff784f, #ff5370);
}

[data-pc-header=header-7] {
  --pc-header-background: linear-gradient(to right, #11c788, #54e2b7);
}

[data-pc-header=header-8] {
  --pc-header-background: linear-gradient(to right, #ff5858, #f09819);
}

[data-pc-header=header-9] {
  --pc-header-background: linear-gradient(to right, #39ADB5, #7C4DFF);
}

[data-pc-header=header-10] {
  --pc-header-background: linear-gradient(to right, #473B7B, #30D2BE);
}

[data-pc-header=header-11] {
  --pc-header-background: linear-gradient(to right, #000000, #0fb3c2);
}

[data-pc-header=header-12] {
  --pc-header-background: linear-gradient(to right, #2d0102, #091991);
}