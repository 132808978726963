// =======================================
//     List of variables for layout
// =======================================
:root {
  // body
  --#{$variable-prefix}body-bg: #{$body-bg};
  --bs-body-bg-rgb: #{to-rgb($body-bg)};

  --pc-heading-color: #222;
  --pc-active-background: rgba(255,255,255,.2);

  // Navbar
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #39465F;
  --pc-sidebar-color-rgb: #{to-rgb(#39465F)};
  --pc-sidebar-submenu-border-color: var(--bs-gray-300);
  --pc-sidebar-active-color: #{$primary};
  --pc-sidebar-active-bg: rgba(202,202,202,.3);
  --pc-sidebar-shadow: 2px 0 2.94px 0.06px rgba(4,26,55,.16);
  --pc-sidebar-caption-color: var(--pc-heading-color);
  --pc-sidebar-border: none;

  // header
  --pc-header-background: linear-gradient(to right,#4099ff,#73b4ff);
  --pc-header-color: #{#fff};
  --pc-header-shadow: none;

  // card
  --pc-card-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,.16);
  --pc-card-box-hover-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);

  // horizontal menu
  --pc-header-submenu-background: #{$white};
  --pc-header-submenu-color: #{#39465F};
}
[data-pc-sidebar-theme="dark"]{
  // Navbar
  --pc-sidebar-background: #3b4650;
  --pc-sidebar-color: #FFFFFF;
  --pc-sidebar-color-rgb: #{to-rgb(#FFFFFF)};
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
}

$header-height: 60px;
$sidebar-width: 245px;
$sidebar-collapsed-width: 72px;
$sidebar-collapsed-active-width: 300px;
$sidebar-tab-width: 75px;
$sidebar-tab-navbar-width: 320px;

// horizontal menu
$topbar-height: 60px;

$soft-bg-level: -90%;
// =====================================
//      Variables for dark layouts
// =====================================
$dark-layout-color: #2a3139;

// =====================================
//      Variables for bootstrap color
// =====================================

$blue: $blue-500;
$secondary: #6c757d;
$indigo: $indigo-500;
$purple: $purple-500;
$pink: $pink-500;
$red: $red-500;
$orange: $orange-500;
$yellow: $yellow-500;
$green: $green-500;
$teal: $teal-500;
$cyan: $cyan-500;

$primary-text: $blue-600;
$secondary-text: #748892;
$success-text: $green-600;
$info-text: $cyan-700;
$warning-text: $yellow-700;
$danger-text: $red-600;
$light-text: #39465F;
$dark-text: $gray-700;

$primary-bg-subtle: $blue-100;
$secondary-bg-subtle: $gray-100;
$success-bg-subtle: $green-100;
$info-bg-subtle: $cyan-100;
$warning-bg-subtle: $yellow-100;
$danger-bg-subtle: $red-100;
$light-bg-subtle: mix($gray-100, $white);
$dark-bg-subtle: $gray-400;

$primary-border-subtle: $blue-200;
$secondary-border-subtle: $gray-200;
$success-border-subtle: $green-200;
$info-border-subtle: $cyan-200;
$warning-border-subtle: $yellow-200;
$danger-border-subtle: $red-200;
$light-border-subtle: $gray-200;
$dark-border-subtle: $gray-500;
$brand-colors:(
  'brand-color-1' :$brand-color1,
  'brand-color-2' :$brand-color2,
  'brand-color-3' :$brand-color3,
  'brand-color-4' :$brand-color4,
  'brand-color-5' :$brand-color5,
  'brand-color-6' :$brand-color6,
  'brand-color-7' :$brand-color7,
  'brand-color-8' :$brand-color8,
  'brand-color-9' :$brand-color9,
  'brand-color-10' :$brand-color10,
  'brand-color-11' :$brand-color11,
  'brand-color-12' :$brand-color12,
);
$preset-colors: (
  preset-1: (
    primary: $blue-500
  ),
  preset-2: (
    primary: $indigo-500
  ),
  preset-3: (
    primary: $purple-500
  ),
  preset-4: (
    primary: $pink-500
  ),
  preset-5: (
    primary: $red-500
  ),
  preset-6: (
    primary: $orange-500
  ),
  preset-7: (
    primary: $yellow-500
  ),
  preset-8: (
    primary: $green-500
  ),
  preset-9: (
    primary: $teal-500
  ),
  preset-10: (
    primary: $cyan-500
  )
);
