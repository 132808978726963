
div.dialog-container{
    position: fixed;
    z-index: 3000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.4);
    > div.dialog-content{
        background-color: #fefefe;
        margin: 15% auto;
        border: 1px solid #888;
        box-shadow: 1px 1px 1px 1px;
        > div.dialog-body{
           width: 100%;
           padding: 40px;
           height: 80%;
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
        };
        > div.dialog-footer{
            width: 100%;
            height: 20%;
            box-shadow: 0px 0px 2px 0px;
            display: flex;
            flex-direction: row;
            justify-content: left;
            position: relative;
        }
    };
}








