// ============================
//    3. Sidebar css start
// ============================

.pc-sidebar {
  background: var(--pc-sidebar-background);
  width: $sidebar-width;
  position: fixed;
  top: $header-height;
  bottom: 0;
  z-index: 1026;
  overflow: hidden;
  box-shadow: var(--pc-sidebar-shadow);
  border-right: var(--pc-sidebar-border);

  .navbar-wrapper {
    width: $sidebar-width;
    background: inherit;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .pc-caption {
    color: var(--pc-sidebar-caption-color);
    display: block;
    padding: 30px 23px 8px !important;
    text-transform: lowercase;
    position: relative;
    line-height: 1.34;
    &:after{
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 100%;
      height: 1px;
      background: var(--pc-sidebar-submenu-border-color);
    }

    &:first-child {
      padding-top: 10px !important;
      &::after {
        display: none;
      }
    }
    font: {
      size: 12px;
      weight: 500;
    }
    svg,
    i {
      display: none;
    }
    label {
      margin-bottom: 0;
    }
    span:not(.badge) {
      display: block;
      color: #67758a;

      font: {
        size: 14px;
        weight: 500;
      }
    }
  }
  .pc-micon {
    margin-right: 8px;
    height: 24px;
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;

    i {
      font-size: 20px;
      vertical-align: middle;

      &.material-icons-two-tone {
        font-size: 22px;
        display: inline-block;
      }
    }

    > svg {
      width: 22px;
      height: 22px;
      display: inline-block;
    }
  }
  .pc-link {
    display: block;
    padding: 14px 20px;
    color: var(--pc-sidebar-color);
    position: relative;
    font: {
      size: 14px;
      weight: 400;
    }
    &:focus,
    &.active,
    &:hover {
      text-decoration: none;
    }
  }

  .pc-navbar > .pc-item {
    > .pc-link {
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 0;
        left: 0;
        bottom: 2px;
      }
    }
    &.active {
      > .pc-link {
        font-weight: 500;
        &:after {
          background: var(--pc-sidebar-active-bg);
          border-left: 4px solid var(--pc-sidebar-active-color);
        }
      }
    }
    &:hover:not(.active) {
      > .pc-link {
        &:after {
          background: var(--pc-sidebar-active-bg);
        }
      }
    }
    .pc-submenu {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        height: calc(100% - 22px);
        width: 1px;
        border-left: 1px solid var(--pc-sidebar-submenu-border-color);
      }
      .pc-item {
        > .pc-link {
          &:after {
            content: '';
            position: absolute;
            top: 20px;
            left: 45px;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: transparent;
          }
          &:before {
            content: '';
            position: absolute;
            top: 22px;
            left: 30px;
            height: 1px;
            width: 18px;
            background: var(--pc-sidebar-submenu-border-color);
          }
        }
        &.pc-trigger,
        &.active {
          > .pc-link {
            font-weight: 600;
            color: var(--pc-sidebar-color);
          }
        }
        &.pc-trigger,
        &.active,
        &:focus,
        &:hover {
          > .pc-link {
            &:after {
              background: var(--pc-sidebar-active-color);
            }
          }
        }
      }
    }
    .pc-submenu {
      &:after {
        left: 30px;
      }
      .pc-link {
        padding: 12px 30px 12px 60px;
        &:before {
          left: 30px;
        }
        &:after {
          left: 28px;
        }
      }
      .pc-submenu {
        &:after {
          left: 46px;
        }
        .pc-link {
          padding: 12px 30px 12px 80px;
          &:before {
            left: 45px;
          }
          &:after {
            left: 62px;
          }
        }

        .pc-submenu {
          &:after {
            left: 63px;
          }
          .pc-link {
            padding: 12px 30px 12px 95px;
            &:before {
              left: 64px;
            }
            &:after {
              left: 79px;
            }
          }
        }
      }
    }
  }
  .pc-item {
    &.disabled {
      a {
        cursor: not-allowed;
        user-select: none;
      }
    }
  }
  .pc-arrow {
    float: right;
    display: inline-block;
    transition: all 0.2s ease-in-out;

    > svg {
      width: 14px;
      height: 14px;
    }
  }
  .pc-badge {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    float: right;
    background: var(--bs-primary);
  }
  .m-header {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    @media (min-width: 1025px) {
      display: none;
    }
  }
  .navbar-content {
    position: relative;
    height: calc(100vh - #{$header-height});
    padding: 10px 0;
  }

  .card {
    box-shadow: none;
    margin: 15px;
    &.nav-action-card {
      .card-body {
        background-size: 200%;
        background-position: center;
      }
    }
  }
  .pc-hasmenu {
    &:not(.pc-trigger) {
      > .pc-submenu {
        display: none;
      }
    }
    &.pc-trigger {
      > .pc-submenu {
        display: block;
      }

      > .pc-link {
        > .pc-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.pc-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.15);
}
[data-pc-sidebar-caption='false'] {
  .pc-sidebar .pc-caption {
    display: none;
  }
}
@media (min-width: 1025px) {
  .navbar-overlay {
    .pc-sidebar {
      &.pc-over-menu-active {
        .navbar-wrapper {
          position: relative;
          z-index: 5;
        }
      }

      &:not(.pc-over-menu-active) {
        left: -#{$sidebar-width};
        box-shadow: none;
      }
    }
  }
  .pc-sidebar {
    transition: width 0.2s ease;
    ~ .pc-header {
      transition: left 0.2s ease;
    }
    ~ .pc-footer,
    ~ .pc-container {
      transition: margin-left 0.2s ease;
    }
    &.pc-sidebar-hide {
      width: 0;
      --pc-sidebar-border: none;
      ~ .pc-header {
        left: 0;
      }
      ~ .pc-footer,
      ~ .pc-container {
        margin-left: 0px;
      }
    }
  }
  .pc-header .pc-h-item.pc-sidebar-popup {
    display: none;
  }
}
@media (max-width: 1024px) {
  .pc-header .pc-h-item.pc-sidebar-collapse {
    display: none;
  }
  .pc-sidebar {
    left: -#{$sidebar-width};
    box-shadow: none;
    top: 0;
    transition: all 0.2s ease-in-out;
    &.mob-sidebar-active {
      left: 0;

      .navbar-wrapper {
        position: relative;
        z-index: 5;
        background: inherit;
      }
    }
  }
}
// ============================
//    3. Sidebar css end
// ============================
