/**  =====================
    20. Authentication css start
==========================  **/

.auth-main {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  .auth-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    .saprator {
      position: relative;
      display: flex;
      align-self: center;
      justify-content: center;
      width: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--bs-border-color);
        z-index: 1;
      }

      span {
        font-size: 0.875rem;
        padding: 8px 24px;
        background: var(--bs-card-bg);
        z-index: 5;
        text-transform: capitalize;
        color: var(--pc-heading-color);
        font-weight: 500;
      }
    }

    .auth-form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      .card {
        width: 100%;
        max-width: 400px;

        @include media-breakpoint-down(md) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }

      img+span {
        padding-left: 10px;
      }

      h5 {
        span {
          text-decoration: underline;
        }
      }
    }
  }

  .auth-sidefooter {
    width: 600px;
    padding: 60px 30px;
    color: var(--bs-body-color);

    .footer-link {
      position: relative;

      li+li {
        position: relative;
        padding-left: 12px;

        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          left: 0;
          background: currentColor;
          opacity: 0.2;
        }
      }
    }

    a {
      color: var(--bs-body-color);

      &:active,
      &:focus,
      &:hover {
        color: var(--bs-primary);
      }
    }
  }

  &.v1 {
    .auth-wrapper {
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;

      .auth-form {
        width: 100%;
      }

      .card {
        .card-body {
          padding: 45px 40px;
        }
      }
    }
  }

  &.v2 {
    background: url('../images/authentication/img-auth-bg.jpg');
    background-size: cover;
    background-position: center;

    &>* {
      position: relative;
      z-index: 5;
    }

    .bg-overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      backdrop-filter: blur(1px);
      opacity: 0.85;
    }

    .auth-wrapper {
      align-items: flex-end;
    }

    .auth-sidecontent {
      width: 50%;
      flex-grow: 1;
      display: flex;
      justify-content: center;

      .auth-sidefooter {
        width: 600px;
        color: $white;

        a {
          color: $white;
        }
      }

    }
    .auth-form {
      width: 400px;
      flex-grow: 0;

      .card {
        border: none;
        box-shadow: none;
        min-height: 100vh;
        margin-bottom: 0;
        border-radius: 0px;

        .card-body {
          padding: 45px 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .auth-sidecontent {
        display: none;
      }
      .auth-form {
        flex-grow: 1;
        .card{
          min-height: auto;
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }
}

/**  =====================
    20. Authentication css end
==========================  **/